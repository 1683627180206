$theme-color-primary: #26B096;
$theme-color-secondary: #6c757d;
$theme-color-success: #198754;
$theme-color-warning: #ffc107;
$theme-color-info: #0dcaf0;
$theme-color-danger: #dc3545;

:root {
    --theme-color-primary: #{$theme-color-primary};
    --theme-color-secondary: #{$theme-color-secondary};
    --theme-color-success: #{$theme-color-success};
    --theme-color-warning: #{$theme-color-warning};
    --theme-color-info: #{$theme-color-info};
    --theme-color-danger: #{$theme-color-danger};
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #F2F4F8 !important;
    max-height: 100vh !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

a {
    text-decoration: none !important;
}

/* Logo img */
// .logo_img {
//     position: absolute;
//     z-index: 1;
//     margin-left: 0rem;
//     margin-top: 0px;
//     cursor: pointer;
// }

.common_display_flex_style {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: flex-start !important;
    column-gap: 10px !important;
}

/* Topbar Css */
.menu_icon {
    background: #EEF9F7;
    padding: 12px;
    border-radius: 25px;
    cursor: pointer;
    margin-left: 20px;
    margin-top: 0px;
    font-size: 39px;
    position: fixed;
}

.header {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-end !important;
    align-items: center !important;
    height: 100%;
}

.user_details_dropdown {
    background-color: #00B29D !important;
    border: none !important;
    height: 30px;
    display: flex !important;
    align-items: center !important;
}

.dropdown_menu:active {
    background-color: #00B29D !important;
}

.topbar_header {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    background: white !important;
    padding: 8px 15px !important;
    margin-left: 0px !important;
    width: 100% !important;
    position: fixed !important;
    top: 0;
}

.topbar_profile {
    border-radius: 50% !important;
    margin-right: -6px !important;
}

.top_bar_icon_color {
    color: var(--theme-color-primary) !important;
    cursor: pointer !important;
}

.top_bar_icon_color:hover {
    color: #007bff !important;
}

.common_container {
    margin-top: 45px !important;
    position: absolute;
    z-index: -1;
    margin-left: 75px !important;
    margin-right: auto !important;
}

.common_container_noleft {
    margin-top: 25px !important;
    position: absolute;
    z-index: -1;
    margin-left: 0px !important;
    margin-right: auto !important;
}

.common_container_top {
    margin-top: 240px !important;
    position: absolute;
    z-index: -1;
    margin-left: 75px !important;
    margin-right: auto !important;
}

.common_container_top1 {
    margin-top: 280px !important;
    position: absolute;
    z-index: -1;
    margin-left: 75px !important;
    margin-right: auto !important;
}

.common_container2 {
    margin-top: 45px !important;
    position: absolute;
    z-index: -1;
    margin-left: 25px !important;
    margin-right: auto !important;
}

.sidebar {
    position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 7rem; //remove sidebar
    height: 145% !important;
    background: white;
    transition: width 0.4s;
    overflow-y: auto !important;
    margin-top: 55px;
    scrollbar-width: none !important;
    z-index: -1;
}

body.open .sidebar {
    width: 7rem;
}

.sidebar-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 10rem;
    height: inherit;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
}

.sidebar-header {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 1.25rem 0 0;
}

.sidebar-header span {
    text-transform: uppercase;
    margin-left: 0.5rem;
    color: #ffffff;
}

.sidebar-logo {
    height: 20px;
    opacity: 0;
    transition: 0.3s;
}

body.open .sidebar-logo {
    opacity: 1;
}

.sidebar-nav {
    display: flex !important;
    padding-top: 10px !important;
    flex: 1 1 auto !important;
    flex-direction: column !important;
    margin-top: 0px !important;
}

.sidebar-nav>button,
.sidebar-footer>button {
    display: flex;
    gap: 25px;
    align-items: center;
    height: 50px;
    width: 100%;
    font-size: 16px;
    font-weight: 200;
    letter-spacing: 2px;
    line-height: 1;
    padding: 0 25px;
    border: none;
    background: white;
}

.sidebar button>svg {
    width: 24px;
    height: 24px;
}

.sidebar button>span {
    color: #f9f9f9;
    opacity: 0;
    transition: 0.3s;
}

@keyframes appear {
    0% {
        opacity: 0;
        translate: 0 10px;
    }

    100% {
        opacity: 1;
        translate: 0;
    }
}

body.open .sidebar button>span {
    opacity: 1;
    animation: appear 0.3s both;
}

.sidebar-nav a>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 8px;
}

.sidebar_text {
    font-size: 13px;
    color: grey;
}

.sidebar_icon {
    color: grey;
    font-size: 20px;
}

/* main Content */
.main_content {
    margin-left: 100px;
    margin-right: auto;
    margin-top: 20px;
}

/* input field */
.floating-label {
    position: relative;
    margin-bottom: 20px;
}

.floating-label input {
    width: 100%;
    padding: 7px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    transition: border-color 0.3s ease;
    // height: 47px;
}

.floating-label textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    transition: border-color 0.3s ease;
}

.floating-label label {
    position: absolute;
    left: 17px;
    top: -10px;
    font-size: 12px;
    color: var(--theme-color-primary);
    transition: top 0.3s ease, font-size 0.3s ease, color 0.3s ease;
    z-index: 1;
    background: white;
    padding: 0px 4px;
}

.floating-label input:focus {
    border-color: var(--theme-color-primary);
    outline: none;
}

.floating-label textarea:focus {
    border-color: var(--theme-color-primary);
    outline: none;
}

.floating-label input:focus+label,
.floating-label input:not(:placeholder-shown)+label {
    top: -10px;
    font-size: 12px;
    color: var(--theme-color-primary);
    background-color: #ffffff;
    padding: 0 4px;
    border-radius: 4px;
    left: 17px;
}

.input-error {
    color: red;
    font-size: 0.8rem;
    margin-top: 5px;
}

/* select field */
.floating-label select {
    width: 100%;
    padding: 6px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 15px;
    transition: border-color 0.3s ease;
    background-color: transparent;
    height: 38px;
}

.floating-label select:focus+label,
.floating-label select:not([value=""])+label,
.floating-label select:valid+label,
.floating-label label.float {
    top: -10px;
    font-size: 12px;
    color: var(--theme-color-primary);
    background-color: #ffffff;
    padding: 0 4px;
    border-radius: 4px;
    left: 17px;
}

.floating-label select:disabled {
    background-color: #eee;
}

.floating-label select:focus {
    border-color: var(--theme-color-primary);
    outline: none;
}

.floating-label1 select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 15px;
    transition: border-color 0.3s ease;
    appearance: none;
    background-color: transparent;
    height: 40px;
}

.floating-label1 select:focus+label,
.floating-label1 select:not([value=""])+label,
.floating-label1 select:valid+label,
.floating-label1 label.float {
    top: -10px;
    font-size: 12px;
    color: var(--theme-color-primary);
    background-color: #ffffff;
    padding: 0 4px;
    border-radius: 4px;
    left: 17px;
}

.floating-label1 select:disabled {
    background-color: #eee;
}

.floating-label1 select:focus {
    border-color: var(--theme-color-primary);
    outline: none;
}

.common_button {
    background-color: var(--theme-color-primary) !important;
    border: none !important;
    border-radius: 7px;
    color: white !important;
    font-size: 12pt !important;
}

.common_button1 {
    background-color: var(--theme-color-primary) !important;
    border: none !important;
    border-radius: 7px;
    color: white !important;
    font-size: 12px !important;
}

.common_button2 {
    background-color: white !important;
    border: none !important;
    border-radius: 7px;
    color: black !important;
    font-size: 12pt !important;
    width: 100%;
    text-align: left;
    padding: 10px 16px;
    margin-bottom: 9%;
}

.common_button3 {
    background-color: white !important;
    border: none !important;
    border-radius: 7px;
    color: var(--theme-color-primary) !important;
    font-size: 12pt !important;
    width: 100%;
    text-align: left !important;
    padding: 16px;
    font-weight: 700 !important;
    margin-bottom: 10px;
}

.common_button4 {
    background-color: white !important;
    border: 1px solid var(--theme-color-primary) !important;
    border-radius: 7px;
    color: var(--theme-color-primary) !important;
    font-size: 12px !important;
    width: 100%;
    text-align: left !important;
    padding: 16px;
    // font-weight: 700 !important;
    margin-bottom: -6px;
}

.common_button5 {
    background-color: #FCB351 !important;
    border: none !important;
    border-radius: 7px;
    color: white !important;
    font-size: 12px !important;
    width: 100%;
    text-align: left !important;
    padding: 16px;
    font-weight: 700 !important;
    margin-bottom: -6px;
}

.common_button_icon {
    background-color: white !important;
    border: none !important;
    border-radius: 7px !important;
    color: var(--theme-color-primary) !important;
    font-size: 12pt !important;
    border: 1px solid var(--theme-color-primary) !important;
}

.common_button_icon1 {
    background-color: white !important;
    border: none !important;
    border-radius: 7px;
    color: var(--theme-color-primary) !important;
    font-size: 12px !important;
    border: 1px solid var(--theme-color-primary) !important;
}

.button-icon {
    margin-top: 2px;
    margin-right: 10px;
}

.button-icon1 {
    margin-top: 2px;
    margin-right: 0px;
}

.image_preview {
    width: 100%;
    height: 100%;
}

/* button style */
.btn_style {
    background: #00B29D !important;
    border: none !important;
    height: 35px !important;
    font-size: 14px !important;
    margin-left: 10px !important;
    // width:7% !important;
}

.btn_sku_add_style {
    background: #00B29D !important;
    border: none !important;
    font-size: 14px !important;
    margin-left: 10px !important;
    // width:7% !important;
}

.back_button1 {
    color: #26B096 !important;
    font-weight: 600 !important;
    width: 200px !important;
    font-size: 13px !important;
    cursor: pointer !important;
}

.btn_style_disapprove {
    background: #e31e24 !important;
    border: none !important;
    height: 35px !important;
    font-size: 14px !important;
}

.add_btn {
    background: white !important;
    color: #00B29D !important;
    border: 1px solid #00B29D !important;
    height: 38px !important;
}

/* card content */
.card-header,
.card-footer {
    border: none !important;
}

.card-body {
    height: 450px !important;
    overflow-y: auto !important;
    scrollbar-width: none;
}

// loader CSS
.loader-container {
    width: 100% !important;
    height: 100vh !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    position: fixed !important;
    background-color: rgba(255, 255, 255, 0.7) !important;
    z-index: 99 !important;
}

.spinner {
    width: 64px;
    height: 64px;
    border: 4px solid;
    border-color: var(--theme-color-primary) transparent var(--theme-color-primary) transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.card_header_text {
    text-align: center !important;
}

.no_data_found {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
}

.company_label {
    margin-bottom: 4px !important;
}

.company_value {
    font-size: 15px;
    font-weight: 700;
    color: var(--theme-color-primary);
}

//react select
.css-1jqq78o-placeholder {
    color: black !important;
    text-align: left !important;
}

.css-wsp0cs-MultiValueGeneric {
    color: black !important;
}

.css-3w2yfm-ValueContainer {
    padding: 5px 8px !important;
}

.css-1nmdiq5-menu {
    // position: relative !important;
    z-index: 99 !important;
    text-align: left !important;
}

.css-1dimb5e-singleValue {
    text-align: left !important;
    font-size: 14px !important;
}

.css-1xc3v61-indicatorContainer,
.css-1u9des2-indicatorSeparator {
    display: none !important;
}

.css-tj5bde-Svg {
    color: white !important;
}

.css-8mmkcg {
    color: white !important
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
    opacity: 0.3 !important;
}

.text_success {
    color: var(--theme-color-primary);
}

//service sheet
.service_sheet {
    position: relative !important;
    top: 0 !important;
    left: 70% !important;
    width: 30% !important;
    height: 100% !important;
    background-color: white !important;
    z-index: 9999 !important;
    overflow-y: auto !important;
}

.service_sheet_content {
    display: flex;
    justify-content: space-between;
    padding: 5px 15px;
}

.service_sheet_custom_spec {
    @extend .service_sheet;
    left: 50% !important;
    width: 50% !important;
}

.service_sheet_service_area {
    position: relative !important;
    top: 0 !important;
    left: 35% !important;
    width: 65% !important;
    height: 100% !important;
    background-color: white !important;
    z-index: 9999 !important;
    overflow-y: auto !important;
}

.icon_color {
    font-size: 18px;
    color: var(--theme-color-primary);
    cursor: pointer;
}

.icon_color_trash {
    @extend .icon_color;
    color: #e31e24
}

.icon_color_edit {
    @extend .icon_color;
    color: darkblue
}

.remove_card_icon {
    position: relative;
    left: 95%;
    top: 8%;
    color: var(--theme-color-primary);
    background: #E3FFEF;
    padding: 3px;
    border-radius: 10px;
    font-size: 20px;
    cursor: pointer;
}

.edit_card_icon {
    position: relative;
    left: 85%;
    top: 8%;
    color: var(--theme-color-primary);
    background: #E3FFEF;
    padding: 6px;
    border-radius: 10px;
    font-size: 20px;
    cursor: pointer;
}

.edit_card_icon_config {
    position: absolute;
    top: -10px;
    right: 15px;
    color: var(--theme-color-primary);
    background: #E3FFEF;
    padding: 4px;
    border-radius: 10px;
    font-size: 20px;
    cursor: pointer;
}

.remove_card_icon_config {
    position: absolute;
    top: -10px;
    right: -8px;
    color: var(--theme-color-primary);
    background: #E3FFEF;
    padding: 4px;
    border-radius: 10px;
    font-size: 20px;
    cursor: pointer;
}

.Banner_requirement_style {
    color: #868686;
    font-size: 10px;
    max-width: 100;
    margin-top: 0px;
    font-style: italic;
}

//carousel indicators
.carousel-indicators [data-bs-target] {
    background-color: green !important;
    width: 5px !important;
    height: 5px !important;
    border-radius: 50% !important;
}

.carousel-indicators {
    margin-bottom: -25px !important;
    z-index: 0 !important;
}

.carousel-control-next,
.carousel-control-prev {
    display: none !important;
}

.disabled_class {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
}

.accordion-header {
    position: relative !important;
    z-index: 1 !important;
}

.modal-header {
    border: none !important;
}

.modal-footer {
    border: none !important;
}

//number field up and down arrow hidden
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.icon_percent {
    position: absolute;
    top: 10px;
    right: 23px;
    color: var(--theme-color-primary);
}

.back_button {
    border: none !important;
    border-radius: 7px !important;
    background-color: var(--theme-color-primary) !important;
    font-size: 12pt !important;
    color: #fff !important;
}

.footer_button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: end;
    gap: 10px;
}

::placeholder {
    font-size: 12px;
    height: 100%;
    width: 100%;
}

.preview_uploaded_image {
    // margin-left: 20px !important;
    position: relative !important;
    width: 130px !important;
    height: 80px !important;
    font-weight: bold !important;
    border-radius: 2px !important;
    border: 1px solid #00b29d !important;
}

.preview_uploaded_image:hover {
    width: 270px !important;
    height: 150px !important;
    border: 1px solid #00b29d !important;
}

.close_button_image {
    border: 1px solid #0e0e0e;
    color: #0e0e0e;
    border-radius: 50%;
    position: absolute;
    top: -6px;
    right: -8px;
    font-size: 15px;
    cursor: pointer;
    outline: none;
    width: 23px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999 !important;
}

.close_button_image:hover {
    border: 1px solid #00b29d;
    color: #00b29d;
    z-index: 999 !important;
}

.view_more_less_btn {
    color: var(--theme-color-primary);
    font-size: 13px;
    cursor: pointer;
}

.price_details_list_grp {
    font-size: 14px;
}

.card_style {
    height: 400px !important;
    overflow-y: auto !important;
    scrollbar-width: none !important;
    background-color: #F2F4F8 !important;
    border: none !important;
    overflow-x: hidden !important;
    margin-bottom: 10px !important;
}

.tab_button {
    height: 45px !important;
    margin: 0px !important;
    width: 7% !important;
}

.common {
    color: #000000 !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    background: transparent !important;
    border: none !important;
}

.active_menu {
    color: #26B096 !important;
    text-decoration: underline !important;
    text-underline-offset: 21px !important;
    text-decoration-thickness: 2px !important;
}

.header_text {
    font-weight: 600 !important;
    font-size: 18px !important;
    text-align: center !important;
}

.back_style {
    color: var(--theme-color-primary) !important;
    font-weight: 600 !important;
}

.segment_list_card_style {
    border: 1px solid #BDBDBD !important;
    border-radius: 5px !important;
    padding: 5px !important;
    font-size: 13px !important;
    margin-left: 10px !important;
    margin-bottom: 10px !important;
}

.title_text {
    font-weight: 600 !important;
    font-size: 18px !important;
    margin-top: 10px !important;
    color: var(--theme-color-primary) !important;
}

.header_button {
    font-size: 28px !important;
    font-weight: 700 !important;
    display: flex !important;
    justify-content: space-between !important;
    flex-direction: row !important;
}

.header_button1 {
    font-size: 28px !important;
    font-weight: 700 !important;
    display: flex !important;
    justify-content: flex-start !important;
    flex-direction: row !important;
}

.header_button2 {
    font-size: 18px !important;
    font-weight: 700 !important;
    display: flex !important;
    justify-content: space-between !important;
    flex-direction: row !important;
    padding: 10px;
}

.no_data {
    height: 500px !important;
    width: 100% !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
}

.logo_image {
    width: 80px !important;
    height: 70px !important;
}

.company_title {
    margin-left: 10px !important;
    margin-bottom: 10px !important;
    font-weight: 700 !important;
    font-size: 17px !important;
    margin-top: 10px !important;
}

.status_button {
    align-items: end !important;
    justify-content: end !important;
    display: flex !important;
    margin-right: 10px !important;
    background-color: #EE6352 !important;
    border-radius: 7px !important;
    border: none !important;
    color: white !important;
    font-size: 13px !important;
    padding: 5px !important;
}

.status_button1 {
    align-items: end !important;
    justify-content: end !important;
    display: flex !important;
    margin-right: 10px !important;
    background-color: #9494e0 !important;
    border-radius: 7px !important;
    border: none !important;
    color: white !important;
    font-size: 13px !important;
    padding: 5px !important;
}

.status_button2 {
    align-items: end !important;
    justify-content: end !important;
    display: flex !important;
    margin-right: 10px !important;
    background-color: #7cdf5f !important;
    border-radius: 7px !important;
    border: none !important;
    color: white !important;
    font-size: 13px !important;
    padding: 5px !important
}

.status_button3 {
    align-items: end !important;
    justify-content: end !important;
    display: flex !important;
    margin-right: 10px !important;
    background-color: #dcca3e !important;
    border-radius: 7px !important;
    border: none !important;
    color: white !important;
    font-size: 13px !important;
    padding: 5px !important;
}

.status_button4 {
    align-items: end !important;
    justify-content: end !important;
    display: flex !important;
    margin-right: 10px !important;
    background-color: #A5D8DD !important;
    border-radius: 7px !important;
    border: none !important;
    color: white !important;
    font-size: 13px !important;
    padding: 5px !important;
}

.status_button12 {
    align-items: end !important;
    justify-content: end !important;
    display: flex !important;
    margin-right: 10px !important;
    background-color: burlywood !important;
    border-radius: 7px !important;
    border: none !important;
    color: white !important;
    font-size: 13px !important;
    padding: 5px !important;
}

.button_edit {
    display: flex !important;
    justify-content: end !important;
    margin-right: 25px !important;
}

.edit_button {
    margin-top: 10px !important;
}

.title_icon {
    margin-right: 10px !important;
    color: var(--theme-color-primary) !important;
    margin-top: 0px !important;
    margin-left: 10px !important;
    cursor: pointer;
}

.title_icon_clone_edit {
    margin-right: 10px !important;
    color: var(--theme-color-primary) !important;
    margin-top: -175px !important;
    margin-left: -32px !important;
    cursor: pointer;
}

//pie chart
.pie_chart {
    height: 322px !important;
    width: 400px !important;
    display: flex !important;
    justify-content: center !important;
    margin-left: auto !important;
    margin-right: auto !important;
    padding: 10px !important;
}

//bar chart
.bar_chart {
    padding: 10px !important;
}

.icon_verify {
    color: var(--theme-color-primary) !important;
    position: absolute !important;
    top: 15px !important;
    right: 25px !important;
    font-size: 15px !important;
}

.icon_verify_enter {
    color: var(--theme-color-primary) !important;
    position: absolute !important;
    top: 8px !important;
    right: 25px !important;
    font-size: 13px !important;
}

.card_style_container:hover {
    background-color: #EAF2FD !important;
    cursor: pointer !important;
}

.purge_card_style_container {
    background-color: #ffffff !important;
}

.customer_list_style {
    display: grid !important;
    padding: 0px 20px 15px 20px !important;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)) !important;

}

.close_btn {
    color: var(--theme-color-primary) !important;
    margin-top: 5px !important;
    cursor: pointer !important;
    font-size: 20px !important;
}

.sub_cat_cancel_button {
    background-color: transparent !important;
    outline: none !important;
    border: none !important;
}

.upload_document {
    margin: 40px !important;
}

iframe {
    border: 0 !important;
}

.table_design {
    width: 100% !important;
    text-align: left !important;
    margin: auto !important;
}

.list_style_sub {
    text-align: left !important;
    list-style: none !important;
    margin-left: -20px !important;
    display: flex !important;
    flex-direction: row !important;
    gap: 6px !important;
    flex-wrap: wrap !important;
}

.sub_cat_list_style {
    background-color: #FFF4E6 !important;
    border-radius: 5px !important;
    padding: 3px 8px !important;
    border: 1px solid #FCB351 !important;
    border-radius: 20px 20px 20px 20px !important;

}

.sub_head {
    color: #00b29d !important;
    width: 100% !important;
    font-weight: 600 !important;
    margin-bottom: 10px !important;
}

.list_cate_sub_cate {
    display: flex !important;
    flex-direction: column !important;
}

.sub_category {
    margin-left: 25px !important;
}

.main_sub_cate {
    display: flex !important;
    align-items: flex-start !important;
    font-size: 13px !important;
    border-bottom: 1px solid #FAFAFA !important;
    margin-top: 8px !important;
}

.image_requirement_style {
    color: #868686 !important;
    font-size: 10px !important;
    width: 160px !important;
    margin-top: 5px !important;
}

.dot_image {
    color: #868686 !important;
    font-size: 7px !important;
    margin-right: 10px !important;
}

.required_color {
    color: #00b29d !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
}

.ql-tooltip {
    left: 0px !important;
}

.tag_name {
    cursor: default !important;
}

.close_button {
    position: absolute !important;
    right: -10px !important;
    top: -11px !important;
    background-color: #868686 !important;
    width: 20px !important;
    height: 20px !important;
    color: #ffffff !important;
    border: none !important;
    border-radius: 50% !important;
    cursor: pointer !important;
    font-size: 14px !important;
    z-index: 1 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.banner_container {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    column-gap: 20px !important;
}

.banner_image_upload {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    margin: 10px !important;
    position: relative !important;
}

.badge_options {
    background-color: #E3FFEF !important;
    width: auto !important;
    padding: 5px !important;
    color: white !important;
    border-radius: 5px !important;
    margin-bottom: 20px !important;
}

.input_card {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
    background-color: #ffffff !important;
    max-width: 500px !important;
    width: 70% !important;
    border-radius: 5px !important;
    padding: 0px 10px !important;
    height: 40px !important;
}

.orders_styles_card {
    width: 100% !important;
}

.orders_style {
    border: none !important;
    background: transparent !important;
    outline: none !important;
    width: 100% !important;
    height: 100% !important;
}

.topbar_class {
    margin-top: 15px !important;
    padding: 0px 15px !important;
    margin-bottom: -10px !important;
}

.sku_search_style {
    @extend .orders_style;
    background-color: lightgray !important;
    width: 100% !important;
    height: 35px !important;
    border-radius: 3px !important;
}

.color_only {
    color: var(--theme-color-primary) !important;
}

.badge_type {
    padding: 10px !important;
    border-radius: 20px !important;
    background-color: var(--theme-color-primary) !important;
    color: white !important;
    border: 1px solid var(--theme-color-primary) !important;
}

.map_selected {
    font-size: 12px !important;
    color: var(--theme-color-primary) !important;
}

.no_product_img {
    width: 25% !important;
    margin-left: 19px !important;
    margin-top: 0px !important;
}


/* Toggle Switch */
.toggle-switch {
    width: 30px;
    height: 15px;
    border-radius: 13px;
    background-color: #ff7878;
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s;
}

.toggle-switch-circle {
    width: 14px;
    height: 15px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    top: 0px;
    left: 1px;
    transition: transform 0.3s;
}

.toggle-switch:not(:last-child) {
    margin-bottom: 1rem;
}

.details_text {
    font-size: 15px;
    font-weight: 600;
    margin-left: 10px;
}

.thankyou_img {
    width: 5rem;
    height: 5rem;
    margin-bottom: 1rem;
}

.button_content {
    display: flex;
    justify-content: center;
}

.order_back_button {
    color: #26B096 !important;
    font-weight: 600;
    width: 200px;
    font-size: 18px;
}

.purchase_order_button {
    background-color: #00929C !important;
    color: #ffffff !important;
    border: 1px solid #00929C;
    border-radius: 5px;
    min-height: 30px;
    margin: 10px 0px 10px 0px;
}

.inactive_purchase_order_button {
    color: #6c757d !important;
    border: 1px solid #6c757d !important;
    border-radius: 5px;
    min-height: 30px;
    margin: 10px 0px 10px 0px;

}

.inactive_purchase_order_button:hover {
    background-color: #00929C !important;
    color: #ffffff !important;
    border-radius: 5px;
    min-height: 30px;
    margin: 10px 0px 10px 0px;
    border: 1px solid #00929C !important;
    opacity: 0.9;
}


.filter_card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: transparent !important;
    width: 100px !important;
    column-gap: 10px;
    height: 100%;
    color: #26B096 !important;
    border-radius: 5px;
    padding: 0px 10px;
    border: 1px solid #26B096 !important;
}

.filter_card:hover,
.filter_card:active {
    background-color: #26B096 !important;
    border: none !important;
    border: 1px solid #26B096 !important;
    color: white !important;
}

.status_card {
    width: 150px;
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 0px 10px;
}

.new_order_button {
    background-color: #00B29D;
    border: none;
    color: #ffffff;
    border-radius: 5px;
    height: 100%;
    align-self: flex-end;
    float: right;
}

.new_order_button:disabled {
    opacity: 0.5 !important;
    background-color: #00b29d !important;

}

.track_order_status_button {
    text-align: right !important;
    color: #007bff !important;
    cursor: pointer !important;
    font-weight: bolder !important;
    font-size: 15px !important;
}

.order_status_button {
    background-color: #27AE60;
    border: none;
    color: #ffffff;
    border-radius: 5px;
    height: 30px;
    font-size: 8px !important;
}

.add_site_order_status_button {
    background-color: #27AE60;
    border: none;
    color: #ffffff;
    border-radius: 5px;
    height: 30px;
}

.order_status_cancelled {
    background-color: #dc3545;
    border: none;
    color: #ffffff;
    border-radius: 5px;
    height: 30px;
    font-size: 10px;
}

.order_card_style {
    margin: 15px 0px !important;
    background-color: #ffffff !important;
    padding: 25px !important;
    line-height: 0.9 !important;
    cursor: pointer !important;

}

.order_card_style:hover {
    margin: 15px 0px;
    background-color: #EAF2FD !important;
    padding: 25px;
    line-height: 0.9;
    line-height: 0.9;
    cursor: pointer;
}

.quotes_card_style {
    margin: 15px 0px !important;
    background-color: #ffffff !important;
    padding: 25px !important;
    line-height: 0.9 !important;
}

.quotes_main_style:hover {
    background-color: #EAF2FD !important;
    cursor: pointer !important;
}

.search_card_order {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    margin: 10px 0px;
    height: 40px;
    column-gap: 10px;
    background-color: none;
}

.filter_main_card {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    column-gap: 20px;
    width: 100%;
}


.search_card {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    margin-top: 10px;
    margin-left: 4px;
    height: 40px;
    column-gap: 10px;
    background-color: none;
}

.card_style_row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.delivered_details {
    text-align: right;
}

.order_details {
    padding: 10px;
}

.list_details_heading {
    font-size: 15px;

}

.list_details {
    color: #00B29D;
    font-weight: 600;
}

.list_details_icon {
    color: #fcb351;
    font-weight: 600;
    margin-left: 12px;
    margin-top: -5px;
    font-size: 20px;
}

.view_detailed_quotes {
    color: #00B29D;
    font-weight: 600;
    cursor: pointer !important;
}

.list_details_card {
    margin-top: 10px;
}

.image_style {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 5px;
}

.expire {
    font-weight: 700;
    font-size: 15px;
    color: #f71d00;
    border: 1px solid #f71d00;
    text-align: center;
    width: 100px;
    padding: 8px 0px;
    border-radius: 5px;
    height: 100%;
    float: right;
}

.quotes_list_card {
    height: auto !important;
    background-color: #F2F4F8 !important;
    border: none !important;
    margin-left: 2px;
}

.cancel_order_button {
    background-color: #ffffff !important;
    color: #EE6352 !important;
    border: 1px solid #EE6352 !important;
    margin-bottom: 20px !important;
    width: 130px !important;
    font-size: 14px !important;
}

.cancel_order_button:hover {
    background-color: #EB5757 !important;
    color: #ffffff !important;
    border: 1px solid #EE6352;
    margin-bottom: 20px !important;
    width: 130px !important;
}

.view_order_heading_card {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
}

.view_order_header_card {
    display: flex;
    flex-direction: column;
}

.dealer_order_menu_card {
    display: flex !important;
    column-gap: 54px;
    flex-wrap: wrap;
}

.horizontal_line {
    margin-top: -7px;
    height: 3px !important;
    background-color: #D1D1D1 !important;
    border: none;
}

.view_order_col2 {
    text-align: right !important;
    margin-top: 0px !important;
    font-size: 15px !important;
}

.view_order_details_sub_card {
    overflow-y: auto !important;
    scrollbar-width: none !important;
    max-height: 500px;
    margin-top: 20px !important;
    font-size: 15px !important;
}

.view_order_card_style {
    width: 100% !important;
    padding: 15px !important;
    margin-bottom: 10px;
    font-size: 14px;
}

.view_order_span {
    color: black;
    font-weight: 500;
}

.view_order_individual_header {
    color: #000000;
    font-weight: 500;
    margin-bottom: 10px !important;
}

.delivery_details_header {
    color: #4C4C4C;
    font-weight: 400;
}

.view_order_joggle_point_card {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
    background-color: #E3FFEF !important;
    padding: 10px 16px !important;
    border-radius: 5px !important;
}

.joggle_point_approved {
    @extend .view_order_joggle_point_card;
    background-color: #FFEBEB !important;
    color: #EB5757 !important;
}

.Joggle_point_rejected {
    color: #EB5757 !important;
    font-weight: 700 !important;
    font-size: 16px !important;
}

.view_order_joggle_point {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    column-gap: 10px !important;
    border: none !important;
}

.joggle_point {
    border: 1px solid #00B29D !important;
    background-color: #ffffff !important;
    color: #00B29D !important;
    border-radius: 5px !important;
    cursor: auto !important;
}

.joggle_point:hover,
.joggle_point:active {
    border: 1px solid #00B29D !important;
    background-color: #ffffff !important;
    color: #00B29D !important;
}

.joggle_point_approve {
    background-color: #00929C !important;
    color: #ffffff !important;
    border: none !important;
}

.joggle_point_approve:hover,
.joggle_point_approve:active {
    background-color: #00929C !important;
    color: #ffffff !important;
}

.joggle_point_reject {
    background-color: #ffffff !important;
    color: #EE6352 !important;
    border: 1px solid #EE6352 !important;
}

.joggle_point_reject:hover,
.joggle_point_reject:active {
    background-color: #EB5757 !important;
    border: 1px solid #EB5757 !important;
    color: #EAF2FD !important;
}

.product_details_card {
    line-height: 0.5;
    margin-top: 10px;
    border-right: 2px dotted #BBBBBB;
    font-size: 12px;
    text-align: center;
}

.last {
    border: none !important;
}

.product_details_list {
    line-height: 1;
    color: #00B29D;
    font-weight: 700;
    font-size: 12px;

}

.product_details_list_card {
    margin: 10px 0px;
    box-shadow: -2px -2px 10px 5px #0000001A;
    padding: 17px 10px 4px 10px;
}

.product_details_list_card_sales {
    margin: 10px 0px;
    box-shadow: -2px -2px 10px 5px #0000001A;
    padding: 17px 10px 4px 10px;
}

// .product_details_list_card_sales:hover {
//     background-color: #EAF2FD !important;
//     cursor: pointer !important;
// }

.separate_sales_order {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
    margin-bottom: 10px !important;
}

.seperate_sales_order_h6 {
    background: #FDF2E5;
    padding: 5px 14px;
    border-radius: 4px;
}

.seperate_sales_order_h6_text {
    background-color: #edf4ff;
    padding: 5px 14px;
    border-radius: 4px;
}

.product_name_status_card {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
    column-gap: 10px;

}

.view_order_total {
    text-align: right;
    font-weight: 700;
}

.grand_total_const {
    font-size: 15px;
    color: #00B29D;
}

.select_option {
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    height: 40px;
    border-radius: none !important;
    outline: none !important;
    margin-bottom: 10px;
    border-bottom: 1px solid #C1C7CD !important;
    font-weight: 400;
}

.options_style {
    font-weight: 600 !important;
}

.sales_invoice_sent_button_style {
    background-color: #00B29D !important;
    color: #ffffff !important;
    border: none !important;
    font-weight: 600 !important;
}

.sales_invoice_sent_button_style:disabled {
    background-color: #313131 !important;
    color: #ffffff !important;
}

//stepper Css  
.stepper-container {
    position: relative !important;
    display: flex !important;
    flex-direction: column !important;
}

.stepper {
    display: flex !important;
    flex-direction: column !important;
    position: relative !important;
    z-index: 1 !important;
}

.step {
    display: flex !important;
    align-items: center !important;
    margin-bottom: 10px !important;
}

.step-number {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.step.initial .step-number {
    background-color: #007bff;
    color: #fff;
}

.step.active .step-number {
    background-color: #007bff !important;
    color: #fff !important;
}

.step.complete .step-number {
    background-color: #28a745 !important;
    color: #fff !important;
}

.track {
    position: absolute;
    left: 15px;
    top: 0;
    width: 4px;
    height: 90%;
    background-color: #ccc;
    z-index: 0;
}

.progress-bar {
    width: 100% !important;
    background-color: #28a745 !important;
    transition: height 0.2s ease !important;
}

.get_back {
    color: #4C4C4C;
}

.comment_card {
    width: 100% !important;
    margin-bottom: 10px;
}

.text_area {
    width: 100%;
    outline: none;
}

.quotes_list_details_style {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 10px;
    row-gap: 10px;
}

.filter_select_style {
    width: 100%;
    height: 43px;
    outline: none;
    font-size: 15px;
    border-radius: 4px;
    border-color: 1px solid #ccc;
}

.list_menu {
    margin-top: -5px;
    background-color: transparent !important;
    outline: none !important;
    border: none !important;
    height: 50px !important;
}

.listFormDetails {
    width: 200px;
}

.dropDown_menu {
    height: 300px
}

.card_header {
    display: flex;
    justify-content: space-between;
    margin-left: 5rem;
    margin-right: 5rem;
    margin-top: 15px;
}

.card_header p {
    font-size: 22px;
    color: var(--theme-color-primary);
    font-weight: bold;
}

.no_sub_category {
    background-color: #FFF4E6 !important;
    color: #000000 !important;
    border-radius: 5px;
    padding: 6px 8px;
    margin-left: 11px;
    max-width: 280px;
    border: 1px solid #dc3545 !important;
}

.voucher_view_card {
    padding: 20px !important;
}

.button_add_style {
    margin-right: 10px;
    margin-top: 0px;
}

.table_head {
    font-weight: 700;
}

.table_data {
    margin: 10px 0px;
    background-color: #E3FFEF;
    align-items: center;
    flex-wrap: wrap;
    padding: 10px;
}

.whole_order_list_card {
    height: auto !important;
    background-color: #F2F4F8 !important;
    border: none !important;
    margin-left: 2px;
}

.dealer_order_menu_card1 {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(130px, max-content)) !important;
    width: 100% !important;
}

.common1 {
    color: #000000;
    font-size: 15px;
    font-weight: 500;
    background: transparent;
    border-bottom: 3px solid #D1D1D1 !important;
    white-space: nowrap;
    /* Prevent text wrapping */
    text-align: center;
    /* Optional: Center align text inside each menu item */
    min-height: 30px !important;
}


.active_menu1 {
    color: #26B096 !important;
    border-bottom: 3px solid #26B096 !important;
    //  min-height: 30px !important;
    //  display: inline-flex !important;
}

.horizontal_line1 {
    margin-top: -7px;
    height: 3px !important;
    background-color: #D1D1D1 !important;
    border: none;
}

.container_for_thankyou {
    background-color: #ffffff;
    margin: 0px;
    padding: 0px;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border: none;
    background-color: #F8F9FD;
}

.thank_you_card {
    width: 100%;
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    border-radius: 20px;
    box-shadow: 0px 0px 0px #F8F9FD;
    text-align: center;
}

.button_style {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
}

.loader_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.session_expire {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #EAF2FD;
}

.session_expired_image {
    width: 80px;
    height: 80px;
}

.card_session {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    font-size: 15px;
    background-color: #F8F9FD;
}

.submit_button {
    text-align: center !important;
    background-color: #26B096 !important;
    color: #ffffff !important;
    border: none !important;
}

.submit_button:hover {
    text-align: center !important;
    background-color: #26B096 !important;
    color: #ffffff !important;
    border: none !important;
}

.submit_button:active {
    text-align: center !important;
    background-color: #26B096 !important;
    color: #ffffff !important;
    border: none !important;
}

.main_container {
    margin: 0px !important;
    padding: 0px !important;
    height: 100vh !important;
    background-color: #ffffff;
}


.modal-content {
    border: none !important;
}

.pdf_icon {
    color: #00b29d !important;
    width: 100% !important;
    height: 45% !important;
    position: absolute !important;
    bottom: 35px !important;
    left: -2px !important;
}

.signin_card {
    display: block !important;
}

.download_icon {
    color: var(--theme-color-primary) !important;
    height: 20px !important;
    width: 20px !important;
    border-radius: 50% !important;
    cursor: pointer !important;
}

.download_icon:hover {
    transform: scale(1.3) !important;
    font-weight: 800 !important;
    color: #007bff !important;
}

.signin_card1 {
    display: block !important;
}

.dropdown-toggle::after {
    display: none !important;
}

.disabled-input {
    background-color: #f2f2f2 !important;
}

.form-control {
    font-size: 12px !important;
    height: 38px !important;
}

/* mobile device */
@media (min-width: 320px) and (max-width: 700px) {
    body.open .sidebar {
        width: 0rem;
    }

    .service_sheet {
        left: 50% !important;
        width: 50% !important;
    }

    .input_card {
        width: 50%;
    }

    .card_session {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 30px;
    }

    .signin_card {
        display: none !important;
    }
}

/* tab device */
@media only screen and (min-width: 701px) and (max-width: 1024px) {
    body.open .sidebar {
        width: 0rem;
    }
    .menu_icon {
        margin-top: 0px;
    }
    .sidebar {
        margin-top: 55px !important;
        height: 94% !important;
    }

    .topbar_header {
        margin-top: 0px !important;
    }

    .main_content {
        margin-left: 45px !important;
        margin-right: auto !important;
        margin-top: 0px !important;
    }

    .common_container {
        margin-top: 75px !important;
    }

    .container,
    .container-md,
    .container-sm {
        max-width: 650px !important;
    }

    .card_style {
        height: 630px !important;
        overflow-y: auto !important;
        scrollbar-width: none !important;
        background-color: #F2F4F8 !important;
        border: none !important;
        overflow-x: hidden !important;
        margin-bottom: 10px !important;
    }

    .common_container_top {
        margin-top: 280px !important;
    }

    .card-body {
        height: 580px !important;
    }

    .tab_width1 {
        width: 100% !important;
    }

    .tab_button {
        width: 10% !important;
    }

    .service_sheet {
        left: 50% !important;
        width: 50% !important;
    }

    .common_container_top1 {
        margin-top: 315px !important;
    }

    .signin_card {
        display: block !important;
    }

}

.search_input_card {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    width: 100%;
    column-gap: 10px;
    font-weight: 600;
    flex-wrap: nowrap;
}

.new_search_input_card {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    width: 100%;
    column-gap: 10px;
    font-weight: 600;
    flex-wrap: nowrap;
    justify-content: flex-end !important;
}

.quotes_list_card_style {
    margin-bottom: 10px !important;

}

.quotes_product_list_style {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    column-gap: 30px !important;
    row-gap: 10px !important;
    flex-wrap: wrap !important;
    margin-top: 10px !important;
}

//customer style
.user_type_search_style {
    width: 100% !important;
    border: none;
}

.site_image_card_style {
    border-radius: 5px !important;
    height: 150px !important;
    width: 150px !important;
    margin-right: 10px !important;
    margin-bottom: 10px !important;
}

.smart_price_sheet {
    position: relative !important;
    top: 0 !important;
    left: 60% !important;
    width: 40% !important;
    height: 100% !important;
    background-color: white !important;
    z-index: 9999 !important;
    overflow-y: auto !important;
}

.pricing_disabled_menu {
    color: lightgrey !important;
}

//dealer price update
.dealer_price_update_stateless {
    color: blue;
    margin-right: 9px;
    margin-top: 3px;
    background: lightblue;
    padding: 5px;
    width: 5%;
    height: 27px;
    border-radius: 5px;
    cursor: pointer;
}

.dealer_price_update_header_text {
    background: var(--theme-color-primary);
    padding: 5px;
    margin-bottom: 15px;
    color: white;
    border-radius: 5px;
    text-align: center;
}

////enquiry style
.table_card_style {
    border-radius: 5px !important;
}

.table_head_row_style {
    background-color: #00B99C !important;
    color: #ffffff !important;
    text-align: center !important;
    font-size: 14px !important;
    width: 100px !important
}

.even_row_style {
    text-align: center !important;
    font-size: 14px !important;
    word-break: break-all;
}

.odd_row_style {
    background-color: #FAFAFA !important;
    text-align: center !important;
    font-size: 14px !important;
    word-break: break-all;
}

.order_status_update {
    background-color: #D4F8D3 !important;
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    text-align: center !important;
    padding: 3px !important;
    font-size: 14px !important;
}

.file_name_list_card_style {
    margin: 0 !important;
    word-break: break-all !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
}

.file_style_list {
    color: #FCB351 !important;
    font-size: 20px !important;
    width: 20px !important;
    cursor: pointer !important;
    margin: 0 !important;
}

.file_style_list:hover {
    color: #007bff !important;
    transform: scale(1.4) !important;
}

.file_card_style {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    column-gap: 10px !important;

}

.file_style_convert_list {
    display: flex;
    align-items: center;
    justify-content: space-between !important;
    gap: 3px;
    font-size: 14px !important;
    cursor: pointer !important;
    margin: 0 !important;
}

.more_file_style {
    color: #007bff !important;
    cursor: pointer !important;
    margin: 0 !important;
    font-size: 10px !important;
    word-break: break-all;
}

.more_file_style:hover {
    color: #007bff !important;
    transform: scale(1.2) !important;
    font-weight: 500 !important;
    word-break: break-all;
}

.model_file_list_style {
    font-size: 15px !important;
    row-gap: 10px !important;
    list-style-type: decimal !important;
}

.status_update_style {
    width: 150px !important
}

.status_select_style {
    padding: 3px !important;
    border: none !important;
    background-color: #7EB1F4 !important;
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    text-align: center !important;
    width: 130px !important;
    cursor: pointer;
}

.Admin_verified {
    background-color: #FFF0BB !important;
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    text-align: center !important;
    padding: 3px 5px !important;
    padding: 3px 5px !important;
    font-size: 14px !important;
    display: inline-block;
}

.Admin_Verification_Failed {
    background-color: #F49A98 !important;
    display: inline-block;
}

.Admin_Verification_Failed {
    background-color: #F49A98 !important;
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    text-align: center !important;
    padding: 3px 5px !important;
    padding: 3px 5px !important;
    font-size: 14px !important;
    display: inline-block;
    word-break: keep-all;
}

.order_heading_style {
    font-weight: bold;
    font-size: 25px;
}

.convert_to_order_style {
    color: #007bff;
    font-size: 20px !important;
    width: 20px !important;
    cursor: pointer;
}

.convert_to_order_style:hover {
    color: #007bff !important;
    font-size: 20px !important;
    transform: scale(1.4) !important;
    width: 20px !important;
    cursor: pointer;
}


.delete_enquiry_list {
    color: red !important;
    cursor: pointer;
}

.delete_enquiry_list:hover {
    transform: scale(1.4) !important;
}

//configuration list
.view_category_icon {
    color: var(--theme-color-primary) !important;
    display: flex !important;
    justify-content: space-between !important;
    width: 15px !important;
    cursor: pointer !important;
}

//configuration list
.view_category_icon:hover {
    transform: scale(1.4) !important;
    color: #007bff !important;
}

.filter_card_style {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
    width: 100% !important;
}

.search_card_style {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
    max-width: 30% !important;
    height: 38px !important;
    background-color: #fff !important;
    border: 1px solid #ccc !important;
    border-radius: 5px !important;
}

.cancel_search_card_style {
    @extend .search_card_style;
    max-width: 60% !important;
    margin: 10px !important;
}

.search_input_card_style {
    width: 100% !important;
}

.search_input_style {
    border: none !important;
    background-color: transparent !important;
    outline: none !important;
    width: 100% !important;
    padding-left: 5px !important;
}

.search_icon_style {
    margin: 3px !important;
}

.gst_search_style {
    max-width: 100% !important;
}

.total_list_count_style {
    font-size: 20px !important;
    font-weight: 500 !important;
}


.delete_enquiry_list_heading {
    width: 20px !important;
}

.main_cart_to_convert_to_order_style {
    display: flex !important;
    justify-content: flex-end !important;
    align-items: center !important;
    column-gap: 10px !important;
}

.logo_image_customer {
    width: 80px !important;
    height: 70px !important;
}

.copy_clipboard1 {
    font-size: 14px !important;
    color: var(--theme-color-primary) !important;
}

.preview_container {
    width: 100% !important;
    margin: 0 auto !important;

}

.preview_uploaded_banner {
    position: relative !important;
    width: 150px !important;
    height: 100px !important;
    font-weight: bold !important;
    border: 1px solid #00b29d !important;
    border-radius: 2px !important;
}

.preview_uploaded_banner:hover {
    width: 270px !important;
    height: 150px !important;
    border: 1px solid #00b29d !important;
}


.table,
.footer {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}
.info,
.address,
.total-row {
    width: 100%;
    border: 1px solid black;
    border-collapse: collapse;
    margin-top: 10px;
    font-size: 14px;
}

.info td,
.address td,
.total-row td,
.table th,
.table td {
    border: 1px solid lightgray;
    padding: 5px;
    font-size: 13px;
}

.title-row th {
    font-size: 14px;
}

.align-right {
    text-align: center;
}

.align-center {
    text-align: center;
}

.distributor_style {
    display: flex;
    flex-direction: row;
    max-height: 100px;
    width: 100%;
}

.distributor_address_style {
    margin-left: 10px;
    color: black;
}

.date_style {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
}

.seperate_sales_order_delete_icon {
    font-size: 16pt !important;
    margin-right: 1rem !important;
    color: red !important;
    cursor: pointer !important;
}

.seperate_sales_order_edit_icon {
    font-size: 16pt !important;
    margin-right: 2rem !important;
    color: blue !important;
    cursor: pointer !important;
}

.purchase_order_button1 {
    background-color: #00929C !important;
    color: #ffffff !important;
    border: none !important;
    border-radius: 5px !important;
    min-height: 30px !important;
}

.common2 {
    color: #000000;
    font-size: 15px;
    font-weight: 500;
    background: transparent;
    border: none;
}

.css-1jqq78o-placeholder {
    font-size: 14px !important;
}

.select_all_checkbox_style {
    background-color: #F4F6F8 !important;
    height: 48px !important;
}

.address_card_style {
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)) !important;
    background-color: transparent !important;
    border: none !important;
}

.address_card_list_style {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    background-color: transparent !important;
    border: none !important;
    margin-bottom: 10px !important;
}

.dealer_search_card_style {
    width: 100% !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
}

.gst_count {
    color: #00929C !important;
    font-weight: 700 !important;
}

//////////////dealer create new order ////////////
.select_address_button_container {
    border: 1px solid #00B29D !important;
    border-radius: 5px !important;
    height: 38px !important;
    background-color: #00B29D !important;
    color: #ffffff !important;
}

.select_address_button {
    width: 100% !important;
    text-align: center !important;
    border: none !important;
    outline: none !important;
    background-color: transparent !important;
    height: 100% !important;
    color: #ffffff !important;
}

.purchase_address_style {
    @extend .address_card_style;
    row-gap: 10px !important;
}

.gst_address_style {
    min-width: 100px !important;
    max-width: 350px !important;
}

.address_cart_style {
    @extend .common_display_flex_style;
    column-gap: 10px !important;
    justify-content: flex-end !important;
}

.cart_style {
    position: relative !important;
}

.cart_count {
    position: absolute !important;
    left: -5px !important;
    top: -5px !important;
    background-color: #DA0B0B !important;
    border-radius: 50% !important;
    color: #ffffff !important;
    width: 15px !important;
    height: 15px !important;
    font-size: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;


}

.current_order_details {
    background-color: #E1E1E1 !important;
    width: 100% !important;
    color: "#1D2939" !important;
    font-size: 16px !important;
    padding: 10px 5px 10px 5px !important;
    height: 38px !important;
    border-radius: 5px !important;
    @extend .common_display_flex_style;
}

.product_card_style {
    margin-top: 20px !important;
}

.product_list_container_style {
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(50px, 350px)) !important;
    gap: 15px !important;
}

.each_product_list_card_style {
    @extend .common_display_flex_style;
    width: 100% !important;
    background-color: #F5F5F5 !important;
    border-radius: 10px !important;
    padding: 16px !important;
    flex-direction: column !important;
}

.each_product_list_card_button_style {
    @extend .common_display_flex_style;
    width: 100% !important;
    margin: auto !important;
    justify-content: space-between !important;
}

.product_list_button_style {
    @extend .common_display_flex_style;
    column-gap: 5px !important;
    font-size: 13px !important;
}

.image_content_card_style {
    @extend .common_display_flex_style;
    align-items: flex-start !important;
    width: 100% !important;
    justify-content: space-between !important;
    height: 100% !important;

}

.image_card {
    max-width: 97px !important;
    height: 97px !important;
}

.content_card_style {
    line-height: 1.5;
    font-size: 12px !important;
}

.content_heading_style {
    font-weight: 600 !important;
    font-size: 16px !important;
}

.content_fav_style {
    color: #FFA458 !important;
}

.offer_style {
    color: #DA0B0B !important;
    font-weight: 600 !important;
}

.sold_details {
    color: #00B29D !important;
}

.fav_style {
    @extend .common_display_flex_style;
    background-color: #FFFFFF !important;
    border-radius: 50% !important;
    width: 25px !important;
    height: 25px !important;
    justify-content: center !important;
    color: '#26B096' !important;
    margin-bottom: 10px !important;
    cursor: pointer;
}

.fav_icon_style {
    color: #DA0B0B !important;
}

.share_icon_style {
    color: #019D9C !important;
}

.cart_button_div_style {
    border: none !important;
    background-color: transparent !important;
    cursor: pointer !important;
}

.cart_button_style {
    color: #FCB351 !important;
    font-size: 20px !important;

}

.cart_button_div_style:disabled {
    opacity: 0.5 !important;
    cursor: auto !important;
}

.active_cart_button_style {
    background-color: #26B096 !important;
    color: #ffffff !important;
    font-size: 20px !important;
    cursor: pointer !important;
}

.address_showing {
    background-color: #00B29D1A !important;
    font-size: 13px !important;
    padding: 11px 16px !important;
}

.address_list_showing_style {
    cursor: pointer !important;
    display: block !important;
    margin: 10px 0px !important;
    font-size: 14px !important;
}

.no_address_found_style {
    text-align: center !important;
    margin-top: 20px !important;
}

.mrp_span {
    color: #777E90 !important;
}

.mrp_price {
    text-decoration: line-through !important;
}

.action_horizontal_line {
    border: 1px dashed #C3C8CE !important;
    width: 100% !important;
}

.proceed_to_buy_style {
    border: 1px solid rgb(0, 178, 157) !important;
    color: #00B29D !important;
    background: transparent !important;
    padding: 3px !important;
    border-radius: 5px !important;
    opacity: 0.8 !important;
}

.proceed_to_buy_style:disabled {
    opacity: 0.5 !important;
}

.proceed_to_buy_cart_style {
    border: none !important;
    color: #00B29D !important;
    background: transparent !important;
}

.proceed_to_buy_cart_style:disabled {
    opacity: 0.5 !important;
    border: none !important;
}

.not_available_style {
    border: 1px solid rgb(0, 178, 157) !important;
    color: #00B29D !important;
    background: transparent !important;
    padding: 3px 100px !important;
    border-radius: 5px !important;
    opacity: 0.8 !important;
}

.not_available_style:disabled {
    opacity: 0.5 !important;
    border: none !important;
}

.compare_button_cont {
    border: 1px solid #00B29D !important;
    color: #ffffff !important;
    background-color: #00B29D !important;
    padding: 3px !important;
    border-radius: 5px !important;
    opacity: 0.8 !important;
}

.compare_button_cont:disabled {
    opacity: 0.5 !important;
}

.compare_button_cart {
    border: none !important;
    color: #ffffff !important;
    background: transparent !important;
}

.compare_button_cart:disabled {
    opacity: 0.5 !important;
}

////dealer cart _item
.dealer_cart_item {
    display: flex !important;
    flex-direction: row !important;
    align-items: flex-start !important;
    justify-content: space-between !important;

}

.dealer_cart_item_image {
    width: 150px !important;
    height: 140px !important;
    position: relative !important;
}

.dealer_cart_checkbox {
    position: absolute !important;
    top: 2px !important;
    left: 4px !important;
}

.dealer_cart_content_item {
    @extend .common_display_flex_style;
    flex-direction: column !important;
    width: 67%;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    line-height: 1;
    font-size: 14px !important;

}

.increase_decrease_style {
    border: 1px solid #00B29D !important;
    border-radius: 3px !important;
    text-align: center !important;
    min-width: 60% !important;
    max-width: 70% !important;
    margin: auto !important;
    margin-top: 5px !important;

}

.quantity_style {
    background-color: #019D9C26 !important;
    font-size: 12px !important;
    padding: 0px 5px !important;
    border-radius: 2px !important;
}

.dealer_incre_decre {
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
    cursor: pointer !important;
}

.dealer_cart_remove_icon {
    font-size: 20px !important;
    color: #ff6b6b !important;
    cursor: pointer !important;
}

.dealer_cart_remove_icon:hover {
    transform: scale(1.2) !important;
    font-weight: 900 !important;
}

.summary_address_card_style {
    border: 1px solid #00B99CBF !important;
    background-color: #FAFAFA !important;
    border-radius: 5px !important;
    padding: 10px 0px !important;
}


.dealer_order_menu_card2 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.active_menu2 {
    color: #00a9b7;
}

.separator {
    margin: 0px 5px 20px 5px;
    color: black;
}

.header_text_product_tmplte_new {
    text-align: left;
    margin-bottom: 15px;
    background: #e4f5f5;
    border-radius: 5px;
    width: 95%;
    height: auto !important;
    margin-left: 15px;
    padding: 10px 0px 0px 0px;
}

.cancel_order_popup_cart {
    @extend .common_display_flex_style;
    flex-direction: column !important;
}

.cancel_image_style {
    width: 30%;
    height: 30%;
}

.ul_list_style {
    align-self: flex-start !important;
}

.active_sort {
    background-color: #00b29d !important;
    color: #ffffff !important;
    font-size: 14px !important;
}

.inactive_sort {
    font-size: 14px !important;
}

.filter_sort_button_style {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    gap: 10px !important;
}

.sort_style {
    cursor: pointer;
}

.distributor_sort {
    @extend .filter_sort_button_style;
    justify-content: flex-end !important;
    margin-top: 8px !important;
}

.Compare_dealer_heading {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    width: 100% !important;
    padding: 0px !important;
}

.compare_icon {
    background-color: #E1B091 !important;
    color: #ffffff !important;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

////compare Dealer ////
.compare_product_list_card_style {
    @extend .common_display_flex_style;
    width: 100% !important;
    border-radius: 10px !important;
    padding: 10px !important;
    font-size: 10px !important;
    justify-content: space-between !important
}


.compare_image_content_card_style {
    @extend .common_display_flex_style;
    flex-direction: row !important;
    gap: 5px !important;
    align-items: flex-start !important;

}

.compare_image_card {
    width: 60px !important;
    height: 70px !important;
}

.compare_content_card_style {
    line-height: 1.4 !important;
    font-size: 10px !important;
}

.compare_table_style {
    width: 100%;
    margin-top: 10px !important;
}

.compare_table_head_row_style {
    background-color: #F9FAFB !important;
    color: #919EAB !important;
    text-align: center !important;
    font-size: 12px !important;
    width: 100px !important;
    padding: 10px 0px !important;
}

.compare_add_to_cart {
    background-color: #00B29D !important;
    border: none !important;
    border-radius: 20px !important;
    font-size: 12px !important;
    padding: 5px 8px !important;
}

.compare_even_row_style {
    text-align: center !important;
    font-size: 12px !important;
    word-break: break-all;
}

.compare_odd_row_style {
    background-color: #FAFAFA !important;
    text-align: center !important;
    font-size: 12px !important;
    word-break: break-all;
}

.Joggle_point_style {
    font-weight: 700 !important;
    color: #00B29D !important;
    font-size: 16px !important;
}

.main_common_card_style {
    background-color: #ffffff !important;
    padding: 26px !important;
    border-radius: 10px !important;
    width: 90vw !important;
    margin-left: 20px !important;
}

///commom loyalt brand list.///
.header_button_new {
    font-size: 20px !important;
    font-weight: 700 !important;
    display: flex !important;
    justify-content: space-between !important;
    flex-direction: row !important;
}

.overview_text {
    padding: 0px 0px 0px 10px !important;
    font-size: 13px !important;
}

.async_select_style {
    width: 300px !important;
}

.feedback_heading_card {
    display: flex !important;
    flex-direction: row !important;
    align-items: flex-start !important;
    column-gap: 10px !important;
}

.feedback_question_card {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    line-height: 0.8;
    width: 100% !important;
    margin-bottom: 10px !important;
    border: 1px solid #B9BABD !important;
    padding: 10px !important;
    border-radius: 5px !important;
}

.feedbcak_question1 {
    font-weight: 600;
    font-size: 15px !important;
    margin-bottom: 5px !important;
}

.feedbcak_question1_sub {
    font-weight: 600;
    font-size: 13px !important;
    color: #354259 !important;

}

.feedback_button {
    color: #614FB3 !important;
    height: 30px !important;
    font-size: 14px !important;
    background: transparent !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border: none !important;
}

.feedback_button:hover {
    background-color: #614FB3 !important;
    color: #ffffff !important;
}

.feedback_icon {
    margin-top: 8px !important;
    cursor: auto !important;
}

.approve_reject_card {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    column-gap: 10px !important;
    margin: 10px !important;
}

.delete_feedback_card {
    width: 100% !important;
}

.delete_feedback {
    background-color: transparent !important;
    border: 1px solid #B9BABD !important;
    color: #B9BABD !important;
    margin: auto !important;
    width: 100% !important;
}

.image_container {
    border: 1px solid #DCDCDE !important;
    border-radius: 4px !important;
    padding: 3px !important;
    width: 70px !important;
    height: 70px !important;
}

.one_star {
    color: #E12B1D !important;
    font-size: 25px !important;
}

.two_three_star {
    color: #FB9506 !important;
    font-size: 25px !important;
}

.four_five_star {
    color: #2E7D32 !important;
    font-size: 25px !important;

}

.un_liked_star {
    font-size: 25px !important;
    color: #354259 !important;
}

.feedback_question_card_star {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    line-height: 0.8;
    justify-content: space-evenly !important;
    margin-bottom: 20px !important;
    width: 100% !important
}

.feedback_images {
    width: 100% !important;
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)) !important;
    gap: 10px !important;
}

.feedback_sentence {
    margin-top: 0px !important;
    width: 100% !important;
    color: #81858B !important;
    border-radius: 4px !important;
    padding: 2px 10px !important;
    font-size: 14px !important;
    word-break: break-all !important;
}

.ratings_table_container {
    width: 100% !important;
    text-align: center !important;
    border-collapse: collapse;
}

.ratings_table_container th:last-child,
.ratings_table_container td:last-child {
    border-right: none;
}

.product_one_star {
    color: #E12B1D !important;
    font-size: 13px !important;
}

.product_two_three_star {
    color: #FB9506 !important;
    font-size: 13px !important;
}

.product_four_five_star {
    color: #2E7D32 !important;
    font-size: 13px !important;

}

.product_un_liked_star {
    font-size: 13px !important;
    color: #354259 !important;
}

.rating_heading {
    padding: 10px 0px !important;
    font-size: 15px !important;
    border-right: 1px solid #ddd;
    /* Add column border */
    text-align: center;
}

.rating_user_name {
    text-transform: capitalize !important;
}

.approve_order {
    width: 130px !important;
    font-size: 14px !important;
    background-color: #26B096 !important;
    color: #ffffff !important;
    border: none !important;
}

.custom-modal-backdrop {
    background-color: rgba(0, 0, 0, 0.25) !important;
}

.update_Po_Order_pdf {
    color: red !important;
    font-size: 21px !important;
    cursor: pointer !important;
    margin-left: 305px !important;
    margin-top: -4px;
}

.create_purchase_order_btn {
    background: #00B29D !important;
    border: none !important;
    height: 35px !important;
    font-size: 14px !important;
    margin-top: 4px !important;
    position: absolute;
    display: flex;
    left: 80%;
}

.update_purchase_order_btn_text {
    background: #00B29D !important;
    border: none !important;
    height: 35px !important;
    font-size: 14px !important;
    margin-top: -3px !important;
    position: absolute;
    display: flex;
    left: 80%;
}

.update_purchase_order_btn {
    background: #00B29D !important;
    border: none !important;
    height: 35px !important;
    font-size: 14px !important;
    margin-top: -3px !important;
    position: absolute;
    display: flex;
    left: 80%;
}

.distributor_group_by {
    padding-top: 10px !important;
    color: black;
    font-size: 15px;
    font-weight: 500;
    width: 100% !important;
}

.distributor_group_by_dealer {
    margin-top: 7px;
    font-size: 15px;
    font-weight: 500;
    width: 100%;
    color: black;
}

.accordion-button {
    background-color: #F2F2F2 !important;
    padding-top: 20px !important;
    height: auto !important;
}

.accordion_loyalty {
    background-color: #F2F2F2 !important;
    padding-top: 20px !important;
    height: auto !important;
}


//horizontal stepper Css  
.horizontal_stepper_container {
    position: relative !important;
    display: flex !important;
    flex-direction: row !important;
    margin-top: 20px !important;
}

.horizontal_stepper {
    display: flex !important;
    flex-direction: row !important;
    position: relative !important;
    z-index: 1 !important;
    justify-content: space-evenly !important;
    align-items: center !important;
    width: 100% !important;

}

.horizontal_step {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: flex-start !important;
    margin-top: 0px !important;
    width: 100px !important;
    height: 100% !important;
    text-align: center !important;
}

.horizontal_step_number {
    width: 30px !important;
    height: 30px !important;
    border-radius: 50% !important;
    background-color: #ccc !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-right: 10px !important;
}

.horizontal_step.horizontal_initial .horizontal_step_number {
    background-color: #007bff !important;
    color: #fff !important;
}

.horizontal_step.horizontal_active .horizontal_step_number {
    background-color: #007bff !important;
    color: #fff !important;
}

.horizontal_step.horizontal_complete .horizontal_step_number {
    background-color: #28a745 !important;
    color: #fff !important;
}

.horizontal_track {
    display: flex;
    position: absolute;
    left: 27px;
    top: 13px;
    width: 86%;
    height: 5px;
    z-index: 0;
    border-radius: 2.5px;
    overflow: hidden;
}

.horizontal_track_segment {
    height: 5px;
    transition: background-color 0.3s ease;
}

.horizontal_progress_bar {
    width: 100% !important;
    height: 5px !important;
    background-color: #28a745 !important;
    transition: height 0.2s ease !important;
}

.horizontal_step_name {
    font-size: 14px !important;
}

.get_back {
    color: #4C4C4C;
}

.horizontal_step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100px;
    text-align: center;
}

.horizontal_step_number {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.horizontal_step.horizontal_complete .horizontal_step_number {
    background-color: #28a745;
    /* Green for completed */
    color: #fff;
}

.horizontal_step.horizontal_active .horizontal_step_number {
    background-color: #007bff;
    /* Blue for active */
    color: #fff;
}

.horizontal_step.horizontal_initial .horizontal_step_number {
    background-color: #007bff;
    /* Initial is also active */
    color: #fff;
}

.horizontal_step_name {
    font-size: 14px;
    color: #333;
}

//tootltip color
/* Change the background color of the tooltip to green */
.tooltip {
    background-color: transparent !important;
}

.tooltip .tooltip-inner {
    background-color: var(--theme-color-primary) !important;
    color: white !important;
    font-size: 14px;
    border-radius: 4px;
    padding: 8px;
    max-width: 200px;
    text-align: center;
}

.tooltip .arrow::before {
    border-top-color: var(--theme-color-primary) !important;
}

.title_icon_tracking {
    position: relative;
    top: 0px;
    color: white;
    font-size: 13px;
    background: var(--theme-color-primary);
    padding: 4px;
    border-radius: 5px;
    cursor: pointer;
}

.title_icon_tracking.disabled {
    pointer-events: none;
    color: grey;
    cursor: not-allowed;
    opacity: 0.6;
}

.compare_add_to_cart {
    background-color: #00B29D;
    color: #fff;
    border: none;
    border-radius: 20px;
    padding: 8px 15px;
    font-size: 14px;
    cursor: pointer;
}

////////////new 
.compare_table_style_main {
    width: 100 !important;
    margin-top: 10px !important;
    padding: 5px 10px !important;
    border: 1px solid #00929C80 !important;
    border-radius: 10px !important;
}

.compare_table_head_main {
    margin: 0px !important;
    width: 100% !important;
    background-color: #F9FAFB !important;
    padding: 15px !important;
    font-size: 14px !important;
    text-align: left !important;
    font-weight: bold !important;
    border-radius: 10px !important;

}

.compare_tr_card {
    border: 1px solid #DCDCDE !important;
    font-size: 14px !important;
    margin: 10px 0px !important;
    text-align: left !important;
    border-radius: 10px !important;
    padding: 8px 0px !important;
}


.new_compare_product_list_card_style {
    background-color: #F5F5F5 !important;
    border-radius: 10px !important;
    padding: 10px !important;
    justify-content: space-between !important;
}

.new_compare_image_content_card_style {
    @extend .common_display_flex_style;
    flex-direction: row !important;
    gap: 5px !important;
    justify-content: space-evenly !important;

}

.new_compare_image_card {
    width: 60px !important;
    height: 70px !important;

}

.new_compare_content_card_style {
    font-size: 14px !important;
}

.new_sold_details {
    color: #00B29D !important;
    font-size: 15px !important;
    width: 300px !important;
    font-weight: 600 !important;
}

.product_details_edit_openSheet {
    background: #d3d3d34a;
    padding: 5px 11px 1px;
    font-size: 15px;
    border-radius: 5px;
    height: 65px;
    font-weight: 400;
}

.product_details_edit_openSheet_hr {
    border: 1px dashed lightgray;
}

.nav-tabs {
    background: transparent !important;
    color: black !important;
    border: none !important;
    font-size: 14px !important;
}

.nav-link {
    width: 230px !important;
    border-radius: 6px !important;
    margin-right: 15px !important;
}

.nav-pills,
.nav-pills .show>.nav-link {
    color: white !important;
    background-color: var(--theme-color-primary) !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

.nav-link {
    color: grey !important;
    text-decoration: none !important;
    background: white !important;
    border: 1px solid grey !important;
}

.nav-link.active {
    background-color: var(--theme-color-primary) !important;
    color: white !important;
    border: var(--theme-color-primary) !important;
}

.nav-item {
    margin-bottom: 1px !important;
}

.welcome_to_joggle_style {
    font-weight: 350 !important;
}

///////product page ///
.main_common_card_product_style {
    border-radius: 10px !important;
    width: 89vw !important;
    margin-left: 24px !important;
}

.product_menu_bar {
    background-color: #fff !important;
    width: 100% !important;
    margin: 0px !important;
    height: 60px !important;
    border-radius: 10px !important;
    margin-top: 10px !important;
}

#produtc_menu_progress_bar {
    list-style-type: none !important;
    @extend .common_display_flex_style;
    justify-content: center !important;
    align-items: center !important;
    height: 100% !important;
    font-size: 14px !important;
}

.product_active {
    @extend .common_display_flex_style;
    flex-direction: column !important;
    line-height: 0.2;
    color: #FCB351 !important;
    cursor: auto !important;
}

.product_inactive {
    line-height: 0.2;
    @extend .common_display_flex_style;
    flex-direction: column !important;
    color: #73757A !important;
    cursor: pointer !important;
}

.product_inactive_cursor {
    line-height: 0.2;
    @extend .common_display_flex_style;
    flex-direction: column !important;
    color: #73757A !important;
    cursor: auto !important;
}

.product_menu {
    text-align: center !important;
    background-color: #FCB351 !important;
    color: #fff !important;
    width: 20px !important;
    height: 20px !important;
    border-radius: 50% !important;
    @extend .common_display_flex_style;
    justify-content: center !important;
    border: 1px solid #FCB351 !important;
}

.product_menu_inactive {
    text-align: center !important;
    background-color: transparent !important;
    color: #73757A !important;
    width: 20px !important;
    height: 20px !important;
    border-radius: 50% !important;
    @extend .common_display_flex_style;
    justify-content: center !important;
    border: 1px solid #73757A !important;
}

.product_menu_details_page_style {
    padding: 27px !important;
    min-height: 60px !important;
    width: 100% !important;
    margin: 0px !important;
    border-radius: 10px !important;
    margin-top: 10px !important;
    background-color: #fff !important;
}

.footer_style_menu {
    display: flex !important;
    justify-content: flex-end !important;
    align-items: flex-end !important;
    float: right !important;
}

.product_data_main_card_style {
    border: 1px solid #E1E1E1 !important;
    border-radius: 3px !important;
    padding: 10px !important;
    margin-bottom: 10px !important;
    font-size: 14px !important;
}

.product_data_style {
    @extend .common_display_flex_style;
    align-items: center !important;
    background-color: #00B29D26 !important;
    padding: 8px 16px !important;
    border-radius: 5px !important;
    width: 100% !important;
}

.cat_sub_choosen_style {

    height: 100% !important;
    margin-top: -8px !important;
    height: 7px !important;
}

.bread_cat_sub_choosen_style {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #00B29D !important;
}

.template_icon {
    color: #00B29D !important;
    margin-right: 5px !important;
}

/* button style */
.clear_btn_style {
    background: transparent !important;
    border: 1px solid #757575 !important;
    color: #757575 !important;
    height: 34px !important;
    font-size: 14px !important;
    margin-left: 10px !important;
}

.purge_delete_btn {
    background: var(--theme-color-primary);
    color: white;
    width: 100%;
    border: none;
    border-radius: 5px;
    padding: 5px;
}

//loyalty point start
.loyalty_card{
    display: flex;
    align-items: center;
    column-gap: 3px !important;
}
.loyalty_point{
    font-size: 15px !important;
    color: #D69400 !important;
    font-weight: 700 !important;
}
.loyalty_point_word{
color: #A0A4B0 !important;
font-size: 10px !important;
}
.loyalty_point_approve{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    background-color: #00B99C !important;
    border: none !important;
    font-size: 10px !important;
    border-radius: 15px !important;
    border: 1px solid #00B99C !important;
    height: 25px !important;
}
.loyalty_point_reject {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    background-color: #ffffff !important;
    border: none !important;
    color: #EE6352 !important;
    font-size: 10px !important;
    border: 1px solid #EE6352 !important;
    border-radius:15px !important ;
    height: 25px !important;
    
}